import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router";
import {Box, Flex, Select, Text} from "@chakra-ui/react";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {useAppSelector} from "../../redux/Store";
import {ProductController} from "../../controllers/ProductController";
import {Product, ProductClass} from "../../shared/entities/Product/Product";
import {GalleryListButton} from "../../components/Buttons/GalleryListButton";
import {ProductTypes} from "../../common/constants";
import {ArchiveItem} from "../../components/Inputs/Icons/Icons";
import {BoxRowStyleConfig, BoxStyleConfig} from "../Forms/FormStyleConfigs";
import {URLPaths} from "../../config/application/URLPaths";
import {AddedBreadcrumbsLinks, SetBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {Icon} from "@chakra-ui/icons";
import {Paginator} from "../../components/Paignator/Paginator";
import CommonSection from "./MainPage/CommonSection";
import ProductCard from "./ProductCard";
import {FaArrowRight} from "react-icons/all";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {MainButton} from "../../components/Buttons/MainButton";
import {Helmet} from "react-helmet";

export const Products: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {priv} = usePrivileges();

    const [isLoading, setIsLoading] = useState(true);
    const searchItems = useAppSelector((state) => state.NavBarReducer.searchItems);
    const changeDragAccept = useAppSelector((state) => state.DragAndDropReducer.changeDragAccept);
    const productController = new ProductController(dispatch);
    const {id} = useParams();
    const location = useLocation();
    const containsArchive = location.pathname.includes("archive");

    const [, setShouldRender] = useState(false);
    const [currentPaginatorPosition, setCurrentPaginatorPosition] = useState(0)
    const [previewPaginatorPosition, setPreviewPaginatorPosition] = useState(0)
    const [size, setSize] = useState(50)
    const [limitCount, setLimitCount] = useState(0)
    const isHomePage = (location.pathname === URLPaths.HOME.link || location.pathname === URLPaths.HOME_ARCHIVE.link);
    const isGeneralPage = (isHomePage && (searchItems === null || searchItems.search === ""))
    const [updatedProductList, setProductList] = useState<Array<Product>>([]);
    const [parentProduct, setParentProduct] = useState<Product>();
    const isShowCart = parentProduct && parentProduct.showCart;
    const isProductGroup = parentProduct?.type === ProductTypes.OPTIONS || parentProduct?.type === ProductTypes.CATEGORY || parentProduct?.type === ProductTypes.SUBCATEGORY;

    if (isGeneralPage) {
        SetBreadcrumbsLinks(dispatch, [URLPaths.HOME]);
    }

    function capitalizeWords(text: string) {
        return text
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const tuneProducts = (productList: Array<Product>) => {
        const updatedProductList: Array<Product> = [];
        for (const elem of productList) {
            updatedProductList.push({...elem});
        }

        const parentProduct = updatedProductList?.find((elem: Product) => elem.id === Number(id));
        const filteredChildProducts = updatedProductList?.filter((elem: Product) => elem.id !== Number(id))

        AddedBreadcrumbsLinks(dispatch, !containsArchive ? {
                link: location.pathname,
                text: capitalizeWords(parentProduct?.name || "")
            }
            : {link: location.pathname, text: "Archive"});
        if (filteredChildProducts.length === 0 && parentProduct) {
            filteredChildProducts.push(parentProduct);
        }

        if (priv?.PRODUCT_CREATE) {
            filteredChildProducts.push(new ProductClass(isGeneralPage ? ProductTypes.CATEGORY : ProductTypes.PRODUCT));
        }

        const filteredProducts = containsArchive
            ? filteredChildProducts?.filter((elem: Product) => elem.archive)
            : filteredChildProducts?.filter((elem: Product) => !elem.archive);

        setProductList(priv?.ORDER_EDIT ? filteredProducts : filteredProducts?.filter((v) => !v.hiddenProduct));

        setParentProduct(parentProduct);
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        const reqConfig = {
            params: {
                // page: (currentPaginatorPosition === previewPaginatorPosition) ? 0 : (currentPaginatorPosition > 0) ? currentPaginatorPosition - 1 : 0,
                page: (currentPaginatorPosition === previewPaginatorPosition) ? 0 : (currentPaginatorPosition > 0) ? currentPaginatorPosition - 1 : null,
                size: size,
                id: isHomePage ? 0 : Number(id),
                search_text: "",
                archive: (containsArchive),
            }
        }

        if (searchItems && searchItems.search !== null && searchItems.search && searchItems.search !== "") {
            if (id) {
                reqConfig.params["search_text"] = searchItems.search;
                setIsLoading(false);
                // return;
            } else {
                SetBreadcrumbsLinks(dispatch, [URLPaths.HOME]);
                reqConfig.params["search_text"] = searchItems.search;
            }
        }
        if (isGeneralPage) {
            reqConfig.params.page = null;
        }
        productController.getProducts(reqConfig.params).then(productList => {
            tuneProducts(productList.Some.items === null ? [] : productList.Some.items);
            setLimitCount(productList.Some.count === null ? 0 : productList.Some.count);
        });

        /*if ((currentPaginatorPosition === previewPaginatorPosition)) {
            setCurrentPaginatorPosition(0);
        }*/
        setPreviewPaginatorPosition(currentPaginatorPosition);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPaginatorPosition, id, searchItems.search , containsArchive, changeDragAccept, size]);


    const handlePageChange = (newPage: number) => {
        setPreviewPaginatorPosition(currentPaginatorPosition)
        setCurrentPaginatorPosition(newPage)
    }

    const schemaData = {
        "@context": "https://schema.org",
        "@type": isGeneralPage ? "WebPage" : (isProductGroup ? "ProductCollection" : "Product"),
        "name": isGeneralPage ? "Home Page" : parentProduct?.name || "Product",
        "description": isGeneralPage
            ? "AK Dealer Services provides quality auto parts and services for dealers and customers. Explore our range of products and solutions."
            : parentProduct?.description || "Product description",
        "url": window.location.href,
        ...(isGeneralPage || isProductGroup ? {} : {
            "sku": parentProduct?.id || "SKU",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "USD",
                "price": parentProduct?.price || "0.00",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "url": window.location.href
            },
            "image": parentProduct?.images?.length
                ? parentProduct.images.map(img => `https://backend.worldlns.ru/file/${img.uuid}?size=GENERAL`)
                : ["https://example.com/default-image1.jpg", "https://example.com/default-image2.jpg"]
        })
    };

    const schemaDataHome = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "WebPage",
                "@id": "https://akdealerservices.com/",
                "url": "https://akdealerservices.com/",
                "name": "AK Dealer Services - Quality Auto Parts & Services",
                "isPartOf": {
                    "@id": "https://akdealerservices.com/#website"
                },
                "about": {
                    "@id": "https://akdealerservices.com/#organization"
                },
                "description": "AK Dealer Services provides quality auto parts and services for auto dealers. Explore our range of products and solutions.",
                "inLanguage": "en-US",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [
                            "https://akdealerservices.com/"
                        ]
                    }
                ]
            },
            {
                "@type": "WebSite",
                "@id": "https://akdealerservices.com/#website",
                "url": "https://akdealerservices.com/",
                "name": "AK Dealer Services",
                "description": "Quality Auto Parts & Services",
                "publisher": {
                    "@id": "https://akdealerservices.com/#organization"
                },
                "inLanguage": "en-US"
            },
            {
                "@type": [
                    "Organization",
                    "AutoPartsStore"
                ],
                "@id": "https://akdealerservices.com/#organization",
                "name": "AK Dealer Services",
                "url": "https://akdealerservices.com/",
                "slogan": "Quality Auto Parts & Services",
                "description": "AK Dealer Services provides quality auto parts and services for auto dealers"
            }
        ]
    };
    const getDescription = (text?: string): string => {
        if (!text) return "";
        return text.length > 255 ? text.slice(0, 255) + "..." : text;
    };
    const description = parentProduct?.type === ProductTypes.PRODUCT
        ? getDescription(parentProduct.descriptionCart)
        : getDescription(parentProduct?.description);

    return (
        <>
            <Helmet>
                <title>
                    {isGeneralPage
                        ? "Home Page Title — AK Dealer Services"
                        : `${parentProduct?.name || ""} — AK Dealer Services`}
                </title>
                <meta
                    name="description"
                    content={
                        isGeneralPage
                            ? "AK Dealer Services provides quality auto parts and services for auto dealers. Explore our range of products and solutions."
                            : (description || parentProduct?.name)
                    }
                />
                <link rel="canonical" href={window.location.href}/>
                <script type="application/ld+json">
                    {JSON.stringify(isGeneralPage ? schemaDataHome : schemaData)}
                </script>
            </Helmet>
            <Box>
                {isShowCart && !isLoading && (
                    <ProductCard parentProduct={parentProduct}/>
                )}
                <Box {...BoxStyleConfig}>
                    {!isGeneralPage && (!isShowCart || containsArchive) &&
                        <Box pb={3}>
                            <Breadcrumbs/>
                        </Box>
                    }
                    <Box>
                        {isGeneralPage &&
                            <>
                                <CommonSection productList={updatedProductList}
                                               groupTypes={ProductTypes.SUBCATEGORY}/>
                                <CommonSection productList={updatedProductList}
                                               groupTypes={ProductTypes.CATEGORY}/>
                                <CommonSection productList={updatedProductList}
                                               groupTypes={ProductTypes.ANOTHER}/>
                                {!isLoading &&
                                    <Flex justifyContent={"center"} pt={'72px'}>
                                        <MainButton height={"80px"} width={"302px"} fontSize={16} lineHeight={'120%'}
                                                    onClick={() => navigate(URLPaths.ORDER_BY_CATALOG.link)}
                                        > <Box>
                                            <Text>{'Need more products?'}</Text>
                                            <Text>{'Order from catalog'}</Text>
                                        </Box>
                                        </MainButton>
                                    </Flex>
                                }
                            </>
                        }
                        {(isShowCart && parentProduct.type !== ProductTypes.OPTIONS) &&
                            <Text pb={5} fontSize={22}>Same products</Text>
                        }

                        <CommonSection productList={updatedProductList}
                                       groupTypes={ProductTypes.PRODUCT}
                                       someCondition={isShowCart && parentProduct.type !== ProductTypes.OPTIONS}/>

                        {(isShowCart && parentProduct.type !== ProductTypes.OPTIONS) &&
                            <Flex justifyContent={"center"}>
                                <Box onClick={() => navigate(`${URLPaths.PRODUCTS.link}${parentProduct?.parentId}`)}
                                     cursor={"pointer"}>
                                    <Flex>
                                        <Text fontWeight={"md"}>{`See all ${parentProduct.parentName}`}</Text>
                                        <Icon as={FaArrowRight} px={2} fontSize={28} color={"gray.800"}
                                              cursor={"pointer"}/>
                                    </Flex>
                                    <Box border={"2px"} borderColor={"gray.300"} width={"100%"}/>
                                </Box>
                            </Flex>
                        }
                    </Box>
                    {(priv?.PRODUCT_CREATE && !isLoading) &&
                        <Box pt={1}>
                            <GalleryListButton w={"140px"} textDecoration="underline" height={"38"} fontSize="16px"
                                               leftIcon={<ArchiveItem fontSize="large"/>}
                                               onClick={() => {
                                                   setShouldRender(prevState => !prevState);
                                                   if (id !== undefined) {
                                                       const redirectPath = containsArchive
                                                           ? `${URLPaths.PRODUCTS.link}${id}`
                                                           : `${URLPaths.PRODUCTS_ARCHIVE.link}${id}`;
                                                       navigate(redirectPath)
                                                   } else {
                                                       const redirectPath = containsArchive
                                                           ? `${URLPaths.HOME.link}`
                                                           : `${URLPaths.HOME_ARCHIVE.link}`;
                                                       navigate(redirectPath)
                                                   }
                                               }}>
                                <Flex alignItems="flex-end">{containsArchive ? "Back" : "Archive"}</Flex>
                            </GalleryListButton>
                        </Box>
                    }

                    {!isGeneralPage && !isLoading && !(isShowCart && parentProduct.type !== ProductTypes.OPTIONS) && updatedProductList.length >= 50 &&
                        <Box {...BoxRowStyleConfig} mt="auto">
                            <Paginator limit={limitCount} step={size} current={currentPaginatorPosition}
                                       swapPageTrigger={handlePageChange}/>
                            <Select
                                defaultValue={size}
                                onChange={(event) => setSize(Number(event.target.value))}
                                maxWidth="80px"
                                ml="10"
                            >
                                {[50, 100].map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    );
};
export default Products;